import { computed, ref } from '@vue/composition-api';
import store from '@/store';
import toast from "@/utils/toast";
import { parseDateToString } from '@/auth/utils';
import userStoreModule from '../userStoreModule';

export default function useUserModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'admin';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, userStoreModule);
  }

  // Use toast
  const toastification = toast();
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };
  const maxSlots = computed(() => Number(itemLocal.value.maxRoomSlots) + Number(itemLocal.value.maxBedSlots));
  const isSubmitting = ref(false);
  const resetModal = () => {
    isSubmitting.value = false;
    resetItemLocal();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      userId: itemLocal.value.id,
      name: itemLocal.value.name,
      phone: itemLocal.value.phone,
      email: itemLocal.value.email,
    };
    data.expiredIn = parseDateToString(itemLocal.value.expiredIn);

    isSubmitting.value = true;

    store
      .dispatch('admin/updateUserSubscription', data)
      .then(() => {
        isSubmitting.value = false;
      })
      .then(() => {
        refModal.value.toggle('#toggle-btn');
      })
      .then(() => {
        emit('refetch-data');
      })
      .then(() => {
        toastification.showToastUpdateSuccess();
      })
      .catch(error => {
        isSubmitting.value = false;
        toastification.showToastError(error, refForm.value);
      });
  };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    maxSlots,
    resetItemLocal,
    resetModal,
    onSubmit,
  };
}
